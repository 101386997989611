import { graphql } from "gatsby"
import React from "react"
import styled from "styled-components"

import Hero from "../images/blog.jpg"
import GlobalStyle from "../components/globalStyle"
import SEO from "../components/seo"
import Layout from "../components/layout"
import PageHeader from "../components/pageHeader"
import BlogCard from "../components/blogCard"
import Footer from "../components/footer"

const ArticleDiv = styled.div`
  article:last-child {
    border-bottom: 0;
  }
`

export default ({ data }) => {
  return (
    <>
      <Layout>
        <GlobalStyle />
        <SEO title="Expandrr - The Blog" />
        <PageHeader text="Our Blog" image={Hero}></PageHeader>
        <ArticleDiv>
          {data.allMdx.nodes.map(({ excerpt, frontmatter, fields }) => (
            <BlogCard
              title={frontmatter.title}
              excerpt={excerpt}
              slug={fields.slug}
            />
          ))}
        </ArticleDiv>
        <Footer></Footer>
      </Layout>
    </>
  )
}

export const query = graphql`
  query SITE_INDEX_QUERY {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { published: { eq: true } } }
    ) {
      nodes {
        id
        excerpt(pruneLength: 250)
        frontmatter {
          title
        }
        fields {
          slug
        }
      }
    }
  }
`
