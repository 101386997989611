import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const Foot = styled.footer`
  display: flex;
  justify-content: center;
  border-top: 10px solid #ff4136;
  background-color: black;
`

const Links = styled.div`
  padding: 2rem;
  align-self: center;
`

const Logo = styled.div`
  font-family: "SpaceGrotesk";
  font-weight: bold;
  background-color: black;
  color: white;
  font-size: 2rem;
  padding: 2rem;
`
const R = styled.span`
  color: #ff4136;
`

const Footer = () => {
  return (
    <Foot>
      <Logo>
        <Link to="/" css="text-decoration: none; color: inherit;">
          Expandr<R>r</R>
        </Link>
      </Logo>
      <Links>
        <Link to="/blog" css="margin: 0 1rem; color:white">
          Blog
        </Link>
        <Link to="/about" css="margin: 0 1rem; color:white">
          About Us
        </Link>
      </Links>
    </Foot>
  )
}

export default Footer
