import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const Article = styled.article`
  padding: 1rem;
  margin: 2rem 0;

  border-bottom-style: solid;
  border-bottom-color: #ff4136;
  border-bottom-width: 0.5rem;
`

const H1 = styled.h1`
  font-size: 3rem;
  margin-bottom: 1rem;
`

const Excerpt = styled.p`
  padding: 1rem;
`

const BlogCard = ({ title, date, excerpt, slug }) => {
  return (
    <Article>
      <H1>
        <Link to={slug} css="color:black; ">
          {title}
        </Link>
      </H1>
      <Excerpt>{excerpt}</Excerpt>
    </Article>
  )
}

export default BlogCard
